import React, { Fragment, Component } from 'react';
import { BrowserRouter, Switch, Route,withRouter,Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
  import VisitorCustoomerAreaPage from '../Pages/visitorPage'
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import './App.css';
import history from '../../history';
import store from '../../store';
/*    <HeaderTop className="headerTop" /> */
class App extends Component {

  constructor(props, context) {
        super(props, context);
        this.state = {
          authFlag:false,
          adminAuth:false
    } 
   

    
    
  }

 
    render() {
      /*   window.googleTranslateElementInit(); */
        return (
            <Fragment>
                
                <BrowserRouter history={history}>
                
                    <ToastContainer autoClose={2500} position="top-center" />
                  
                    <Switch>

                   
                      <Route exact path="/visitor" component={VisitorCustoomerAreaPage} />
                      <Redirect to="/visitor" />
                      </Switch>
                    
                </BrowserRouter>
            </Fragment>
        );
    }
}

export default compose(
  withReducer("App1", reducer),
  connect()
)(App);   
 {/* <Route exact path="/founder" component={ComingSoon} /> */}