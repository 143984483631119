import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const CHECK_VISITOR_EXIST = "CHECK_VISITOR_EXIST";
export const CHECK_VISITOR_EXIST_LOADING =
"CHECK_VISITOR_EXIST_LOADING";
export const CHECK_VISITOR_EXIST_LOADING_FAILED =
"CHECK_VISITOR_EXIST_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const checkVisitorLoading = ms => ({
    type: CHECK_VISITOR_EXIST_LOADING,
    payload: ms
    });
    
    export const checkVisitorLoadingFailed = ms => ({
    type: CHECK_VISITOR_EXIST_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function checkVisitor(email) {
    const request = axios.get(
    `https://prod-legalemirates.wowets.com/profileService/api/v1/iPadPenal/visitor/checkVisitorAlreadyVisited?email=${email}`);
    return dispatch => {

    dispatch(checkVisitorLoading());
    request
    .then(response => {   
  
    if (response.status == 201 || response.status == 200) {
     console.log(response,"response checkVisitor")
    dispatch({
    type: CHECK_VISITOR_EXIST,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        checkVisitorLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(checkVisitorLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(checkVisitorLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(checkVisitorLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


