import React,{useEffect,useState} from 'react'
import { NavLink, Link } from 'react-router-dom'
/* import './style.css' */
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import VisitorForm from './CustomerVisitorForm'
import withReducer from "../../store/withReducer";
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {Container} from 'react-bootstrap'

function VisitorCustoomerArea ()  {
    
    return (
        <div>  
          {/*       <Header />   */}
        <div className="adminPanel-area">
     
            <Container fluid>
                <div className="adminPanelBackarea">
            
                <div className="row" >
              
                   {/*  <div className="col-12 admin-cols">
                <AdminSideBar />
                    </div> */}
                    <div className="col-12">
                    <VisitorForm />
                    </div>
                </div>
                </div>
            </Container>
        </div>
        </div>
    )
}
export default  withReducer("VisitorCustoomerArea1", reducer)
  (VisitorCustoomerArea); 